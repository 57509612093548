import Footer from "../../components/footer";
import Content from "../../components/content";
import Header from "../../components/header";
import { useEffect } from "react";

export default function Terms() {

    const accountTerms = [
        "You must be at least 13 years old.",
        "You must be a human. Accounts registered by 'bots' or other automated methods are not permitted.",
        "You must provide a valid email address and any other information requested in order to complete the registration process.",
        "Your login may only be used by one person - i.e., a single login may not be shared by multiple people - except that a machine user's actions may be directed by multiple people.",
        "You are responsible for maintaining the security of your account and password. Compete cannot and will not be liable for any loss or damage from your failure to comply with this security obligation.",
        "You are responsible for all activity that occurs under your account.",
    ]

    const cancelation = [
        "You are solely responsible for properly canceling your account. You can cancel your account at any time by using the option in the Compete iOS app.",
        "All of your Content will be immediately deleted from the Service upon cancelation. This information can not be recovered once your account is canceled.",
        "Compete, in its sole discretion, has the right to suspend or terminate your account and refuse any and all current or future use of the Service, or any other Compete service, for any reason at any time. Such termination of the Service will result in the deactivation or deletion of your Account or your access to your Account, and the forfeiture and relinquishment of all Content in your Account. Compete reserves the right to refuse service to anyone for any reason at any time."
    ]

    const modifications = [
        "Compete reserves the right at any time and from time to time to modify or discontinue, temporarily or permanently, the Service (or any part thereof) with or without notice.",
        "Compete shall not be liable to you or to any third-party for any modification, suspension or discontinuance of the Service."
    ]

    const general = [
        "Your use of the Service is at your sole risk. The service is provided on an 'as is' and 'as available' basis.",
        "Support for Compete services is only available in English and German, via email.",
        "You understand that Compete uses third-party vendors and hosting partners to provide the necessary hardware, software, networking, storage, and related technology required to run the Service.",
        "We may, but have no obligation to, remove Content and Accounts containing Content that we determine in our sole discretion are unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise objectionable or violates any party's intellectual property or these Terms of Service.",
        "Verbal, physical, written or other abuse (including threats of abuse or retribution) of any Compete member will result in immediate account termination.",
        "Cheating while playing any game in a match or not is not allowed.",
        "Questions about the Terms of Service should be sent to hello@compete-game.com.",
    ]

    useEffect(() => {
        document.title = 'Terms of Service - Compete';
    }, []);

    return (
        <div>
            <Header isAuthenticationShown={true} />
            <Content>
                <div className="section">
                    <div className="container">
                        <div className="cell legal-cell">
                            <div>
                                <div className="legal-cell-date">UPDATED 15th MAY 2023</div>
                                <h2>Terms of Service</h2>
                            </div>
                            <p>By using the the Compete iOS App or the website ("Service"), you are agreeing to be bound by the following terms and conditions ("Terms of Service").</p>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="container terms">
                        <div>
                            <h2>Account Terms</h2>
                            {accountTerms.map((text, index) => {
                                return (
                                    <div className="terms-cell">
                                        <div className="terms-number">{index + 1}</div>
                                        <p>{text}</p>
                                    </div>
                                );
                            })}
                        </div>
                        <div>
                            <h2>Cancelation & Terminations</h2>
                            {cancelation.map((text, index) => {
                                return (
                                    <div className="terms-cell">
                                        <div className="terms-number">{index + 1}</div>
                                        <p>{text}</p>
                                    </div>
                                );
                            })}
                        </div>
                        <div>
                            <h2>Modifications to the Service</h2>
                            {modifications.map((text, index) => {
                                return (
                                    <div className="terms-cell">
                                        <div className="terms-number">{index + 1}</div>
                                        <p>{text}</p>
                                    </div>
                                );
                            })}
                        </div>
                        <div>
                            <h2>General Conditions</h2>
                            {general.map((text, index) => {
                                return (
                                    <div className="terms-cell">
                                        <div className="terms-number">{index + 1}</div>
                                        <p>{text}</p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </Content>
            <Footer top={true} />
        </div>
    )
}