import Footer from "../../components/footer";
import Content from "../../components/content";
import Header from "../../components/header";
import { useEffect } from "react";

export default function Privacy() {

    useEffect(() => {
        document.title = 'Privacy Policy - Compete';
    }, []);

    return (
        <div>
            <Header />
            <Content>
                <div className="section">
                    <div className="container">
                        <div className="cell legal-cell">
                            <div>
                                <div className="legal-cell-date">UPDATED 15th MAY</div>
                                <h2>Privacy Policy</h2>
                            </div>
                            <p>Dive into the data Compete collects about you. Here we explain what we collect and when we collect it. You can also learn about how it's used - and as importantly, how it's not used. Lastly, let's walk through how you can control and manage this data.</p>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="container privacy">
                        <div>
                            <h2>What data does Compete collect about me, and why?</h2>
                            <p>Compete only collects your e-mail and username when you create an account in the app to reset your password and to display this information in the app. However, Compete does use services from other companies to provide the app and website. The companies behind those services may collect data about you on their own, for their own purposes. Some of these services may be used to collect information about your online activities across different websites.</p>
                        </div>
                        <div>
                            <h2>Does Compete share data about me with others?</h2>
                            <p>Compete does not sell or give information about you to other companies or services. However, Compete does use services from other companies to provide the app and website. The companies behind those services may collect data about you on their own, for their own purposes. Some of these services may be used to collect information about your online activities across different websites.</p>
                        </div>
                        <div>
                            <h2>Where does Compete store data about me?</h2>
                            <p>Compete stores account data, data about website use, and data about content delivery network use on servers in Europe from Google Firebase.</p>
                        </div>
                        <div>
                            <h2>Where can I see and erase data about me?</h2>
                            <p>You can see and change your account data at any time by visiting your profile page on the app. You can delete your account on the settings tab on the app. Compete deletes every account related data too once a account is deleted.</p>
                        </div>
                        <div>
                            <h2>Other Services</h2>
                            <p>Compete uses the following services that collect personal data for the following purposes:</p>
                            <div className="services-table">
                                <div className="services-table-heading">
                                    <div className="services-table-left">
                                        Services
                                    </div>
                                    <div className="services-table-center">
                                        Privacy Notice
                                    </div>
                                    <div className="services-table-right">
                                        Description
                                    </div>
                                </div>
                                <div className="divider-primary"></div>
                                <div className="services-table-cell">
                                    <div className="services-table-left">
                                        <a href="https://firebase.google.com">Firebase</a>
                                    </div>
                                    <div className="services-table-center">
                                        <a href="https://firebase.google.com/support/privacy">https:<wbr></wbr>//firebase.<wbr></wbr>google.<wbr></wbr>com/<wbr></wbr>support/<wbr></wbr>privacy</a>
                                    </div>
                                    <div className="services-table-right">
                                        Compete uses Firebase to store user data, user images, match data, user authentication and for push notifications.
                                    </div>
                                </div>
                                <div className="divider-secondary"></div>
                                <div className="services-table-cell">
                                    <div className="services-table-left">
                                        <a href="https://admob.google.com/home/">AdMob</a>
                                    </div>
                                    <div className="services-table-center">
                                        <a href="https://policies.google.com/technologies/ads">https:<wbr></wbr>//support.<wbr></wbr>google.<wbr></wbr>com/<wbr></wbr>admob/<wbr></wbr>answer/<wbr></wbr>6128543?<wbr></wbr>hl=de#</a>
                                    </div>
                                    <div className="services-table-right">
                                        Compete uses Admob for advertisements in the app.
                                    </div>
                                </div>
                                <div className="divider-secondary"></div>
                            </div>
                        </div>
                        <div>
                            <h2>How can I contact Compete about privacy?</h2>
                            <p>You can send privacy-related questions, complaints, and requests to Compete at hello@compete-game.com.</p>
                        </div>
                    </div>
                </div>
            </Content>
            <Footer top={true} />
        </div>
    )
}