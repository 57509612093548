import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import 'firebase/firestore';
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const config = {
    apiKey: "AIzaSyCUqpn3G2xe7e13rRQo16EF_vOh14YxIWU",
    authDomain: "compete-77308.firebaseapp.com",
    projectId: "compete-77308",
    storageBucket: "compete-77308.appspot.com",
    messagingSenderId: "720502714889",
    appId: "1:720502714889:web:7491491abb750f8916bed8",
    measurementId: "G-PZEF9JE31N"
};

const firebase = initializeApp(config);
const analytics = getAnalytics(firebase);
const auth = getAuth(firebase)
const firestore = getFirestore(firebase);

export { firebase, analytics, auth, firestore };