import Footer from "../../components/footer";
import Header from "../../components/header";
import Content from "../../components/content";
import { useEffect } from "react";

import app_store_badge from "../../assets/assets/app-store-badge.png"
import screen from "../../assets/assets/screen.png"

export default function Start() {

    useEffect(() => {
        document.title = 'Compete';
    }, []);

    return (
        <div>
            <Header />
            <Content>
                <div className="section start-app-section">
                    <div className="container start-app">
                        <div className="start-app-left">
                            <h1>Get the app!</h1>
                            <p>Download the app to start playing against your friends and other people online. Win matches against them to earn points and climb up the ranks.</p>
                            <img src={app_store_badge} onClick={() => window.open("https://apps.apple.com/us/app/compete-play/id6449609581", '_blank')} />
                        </div>
                        <div className="start-app-right">
                            <img src={screen} />
                        </div>
                    </div>
                </div>
            </Content>
            <Footer />
        </div>
    )
}