import { Link } from "react-router-dom";

import logo from "../assets/logo/logo.png"

export default function Footer(props) {
    return (
        <div className="footer">
            <div className="footer-container">
                    <div>© Copyright 2023 David Schreiber</div>
                    <div className="footer-menu">
                        <Link to="/legal/terms">
                            Terms of Service
                        </Link>
                        <Link to="/legal/privacy">
                            Privacy Policy
                        </Link>
                    </div>
            </div>            
        </div>
    );
}