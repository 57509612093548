import { useState } from "react";
import { Link } from "react-router-dom";

import logo from "../assets/logo/logo.png"

export default function Header(props) {

    const [bannerShown, setBannerShown] = useState(true);

    return (
        <div className="header">
            <div className="header-container">
                <Link to="/">
                    <img className="logo" src={logo} />
                </Link>
            </div>
        </div>
    );
}