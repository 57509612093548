import Footer from "../../components/footer";
import Header from "../../components/header";
import Content from "../../components/content";
import { useEffect, useState } from "react";
import TextField from "../../components/textfield";
import Button from '../../components/button';
import { auth } from "../../lib/firebase";
import { confirmPasswordReset } from "firebase/auth"
import { useNavigate } from "react-router-dom";

import checkmark_circle from "../../assets/assets/checkmark.circle.png"

export default function ResetPassword() {

    const queryParameters = new URLSearchParams(window.location.search);
    const code = queryParameters.get("oobCode");

    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const [success, setSuccess] = useState(false);

    const navigate = useNavigate();

    const requirements = {
        "8-32 characters": password.length >= 8 && password.length <= 32,
        "At least 1 lowercase letter, 1 uppercase letter and 1 number": /.*[a-z].*/.test(password) && /.*[A-Z].*/.test(password) && /.*[0-9].*/.test(password),
    }

    async function handleReset() {
        setIsLoading(true);
        confirmPasswordReset(auth, code, password)
            .then(function() {
                setSuccess(true);
            })
            .catch(function() {
                console.log("error");
            })
            
    }

    useEffect(() => {
        if (!code) {
            navigate("/");
        }
        document.title = 'Reset Password - Compete';
    }, []);

    return (
        <div>
            <Header />
            <Content>
                <div className="section">
                    <div className="container password-reset-container">
                        {success ? <p>Password reset succesful.</p> :
                            <div className="password-reset-form">
                                <TextField title="New Password" text={password} setText={setPassword} secure={true} />
                                <div className="requirements">
                                    {Object.entries(requirements).map((requirement, i) => <div className="requirement">{requirement[1] ? <div className="requirement-checkbox-checked"><img src={checkmark_circle} /></div> : <div className="requirement-checkbox"></div>}{requirement[0]}</div>)}
                                </div>
                                <Button text={"Reset password"} height={52} width={"100%"} offset={5} borderRadius={8} color={Object.entries(requirements).map((requirement, i) => requirement[1]).includes(false) ? "rgba(98,104,115,1)" : "rgba(239, 49, 72, 1)"} secondaryColor={true ? "rgba(74,80,91,1)" : "rgba(176, 34, 51, 1)"} onClick={handleReset} disabled={Object.entries(requirements).map((requirement, i) => requirement[1]).includes(false)} />
                            </div>
                        }
                    </div>
                </div>
            </Content>
            <Footer />
        </div>
    )
}