import { motion } from "framer-motion";

export default function Button(props) {

    const height = props.height;
    const width = props.width;
    const offset = props.height * 0.1;

    const borderRadius = props.borderRadius;

    const color = props.color;
    const secondaryColor = props.secondaryColor;

    const text = props.text;

    const isTapped = props.isTapped;
    const disabled = props.disabled;

    const onClick = props.onClick;

    return (
        <motion.div className="button"
            initial={{ 
                height: height,
                width: width,
                position: "relative",
            }}
            disabled={disabled}
        >
            <motion.div
                initial={{ 
                    height: height - offset,
                    width: "100%",
                    y: offset,
                    background: secondaryColor,
                    position: "absolute",
                    borderRadius: height * 0.214,
                }}
                
            ></motion.div>
            <motion.div className="button-top"
                initial={{ 
                    height: height - offset,
                    width: "100%",
                    background: color,
                    position: "absolute",
                    borderRadius: height * 0.214,
                    fontSize: height * 0.32,
                }}
                animate={{
                    y: isTapped ? offset / 2 : 0,
                }}
                whileTap={{y: disabled ? 0 : offset / 2}}
                onClick={() => onClick()}
            >
                {text}
            </motion.div>
        </motion.div>
    )
}